import React from "react"

import Layout from "../components/layout"
import Banner from "../components/banner"
import AktuellesPreview from "../components/aktuelles-preview"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import PagePreview from "../components/page-preview"
import Container from "react-bootstrap/Container"
import Hero from "../components/hero"

const IndexPage = ({ data }) => {
  // const bannerTitle = "Willkommen beim \nWTHC";   // Willkommen beim WTHC \n (Walddörfer Tennis und Hockey Club)";
  const bannerTitle = "Willkommen beim \nWalddörfer THC" // Willkommen beim WTHC \n (Walddörfer Tennis und Hockey Club)";
  const bannerSubtitle =
    "Tennis und Beachvolleyball auf traumhaften Plätzen in der Halle und im Freien. \n Besuchen Sie uns im Herzen Volksdorfs!"
  const bannerCta = {
    title: "Jetzt Platz buchen",
    url: {
      blank: true,
      href:
        "https://www.bookandplay.de/cgi-bin/WebObjects/BookAndPlay.woa/wa/directAccess?id=HTV-2029",
    },
  }
  const vereinDescription = `Seit 1952 bietet der WTHC inmitten des Volksdorfer Walds einen Tennisgenuss der Extraklasse.
  Auf unserer weitläufigen Anlage bestehend aus acht Außenplätzen und zwei Hallenplätzen kommen Jung und Alt auf ihre Kosten.
  Die Gastronomie mit großer Terrasse und Blick auf die Plätze lädt zum Verweilen ein. 
  Schauen Sie vorbei und machen Sie sich selbst ein Bild!`
  const trainingDescription = `Auf der Tennisanlage des WTHC bieten zur Zeit vier selbständig tätige Trainer professionelles 
  Tennistraining für Kinder und Erwachsene an. In Gruppenunterricht oder Einzelstunden sorgen die Trainer dafür, dass euer 
  Aufschlag wieder so richtig in Schwung kommt. Für Kinder und Jugendliche bieten die Trainer zusätzlich ein alljährliches 
  Tenniscamp in den Sommerferien an. Also, wenn ihr schon immer einmal die ein- oder beidhändige Rückhand lernen wolltet... 
  Die Trainer unterstützen euch gerne!
  <br/>
  <br/>
  Alle Trainingsanfragen können entweder unmittelbar an einen der  <a href='/training#trainer'>Trainer</a> oder über die folgende Mailadresse 
  <a href="mailto:trainingsanfragen@wthc-volksdorf.de">trainingsanfragen@wthc-volksdorf.de</a> 
  gleichzeitig an alle auf unserer Tennisanlage selbständig tätigen Trainer gestellt werden.

  <br/>
  <br/>

  Weitere Informationen zu den Trainern sowie zum Jugend- und Erwachsenentraining findet ihr <a href="/training">hier</a>.
  `
  const mannschaftenDescription = `Punkt. Satz. Und Spiel! Woche für Woche messen sich unsere Mannschaften mit anderen Vereinen des Hamburger Tennis-Verbands. 
  Bei einem 6:6 im Tiebreak des letzten Satzes können die Gemüter schon mal erhitzen. Aber bei allem Ehrgeiz steht der Spaß natürlich im Vordergrund.
  Unsere Mannschaften freuen sich immer über neue Mitspieler!`
  const sonstigesDescription = `Und das ist noch nicht alles, für all unsere Mitglieder steht außerdem eine hauseigene Beachvolleyballanlage zur Verfügung.
  Copacabana im Norden Deutschlands, wer hätte das gedacht!`
  return (
    <Layout>
      <Seo title="Home" />
      <Banner image={{ asset: { url: "/images/clubhouse02.jpg" } }}>
        <Hero title={bannerTitle} subtitle={bannerSubtitle} cta={bannerCta} />
      </Banner>
      <AktuellesPreview
        news={data.allMarkdownRemark.edges}
        events={data.allCalendarEvent.edges}
      />
      <Container style={{ maxWidth: "1024px" }}>
        <PagePreview
          title="Verein"
          description={vereinDescription}
          slug="/verein"
          image={data.vereinImg}
          links={[
            { title: "Die Anlage", href: "/verein" },
            { title: "Unsere Gastronomie", href: "/gastronomie" },
            { title: "Mitglied werden", href: "/verein#mitglied-werden" },
          ]}
        />
        <PagePreview
          title="Training"
          description={trainingDescription}
          slug="/training"
          image={data.trainingImg}
          links={
            [
              // { title: 'Trainingsanfrage an alle Trainer', href: 'mailto:training@wthc-volksdorf.de' },
              // { title: 'Jugend-Trainingsanfrage an alle Jugendtrainer', href: 'mailto:jugendtraining@wthc-volksdorf.de' },
              // { title: 'Für Kinder', href: '/training#jugend' },
              // { title: 'Für Erwachsene', href: '/training#erwachsene' },
              // /*{ title: 'Unsere Trainer', href: '/training#trainer' }*/
            ]
          }
          reverse
        />
        <PagePreview
          title="Mannschaften"
          description={mannschaftenDescription}
          slug="/mannschaften"
          image={data.mannschaftenImg}
          links={[
            { title: "Junioren", href: "/mannschaften#junioren" },
            { title: "Damen", href: "/mannschaften#damen" },
            { title: "Herren", href: "/mannschaften#herren" },
          ]}
        />
        <PagePreview
          title="Und vieles mehr..."
          description={sonstigesDescription}
          slug="/sonstiges"
          image={data.sonstigesImg}
          links={[
            { title: "Beachvolleyball", href: "/sonstiges#beachvolleyball" },
            { title: "Racket Service", href: "/sonstiges#racketservice" },
          ]}
          reverse
        />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fields: { slug: { regex: "/news/" } } }
      limit: 2
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM YYYY", locale: "de")
          }
          excerpt
          fields {
            slug
          }
        }
      }
    }
    allCalendarEvent(limit: 3, sort: { fields: [end___dateTime], order: ASC }) {
      edges {
        node {
          id
          summary
          start {
            dateTime
          }
          end {
            dateTime
          }
        }
      }
    }
    vereinImg: file(relativePath: { eq: "clubhouse03.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 350)
      }
    }
    trainingImg: file(relativePath: { eq: "trainingmaedchen.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 350)
      }
    }
    mannschaftenImg: file(relativePath: { eq: "herren50_01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 350)
      }
    }
    sonstigesImg: file(relativePath: { eq: "beachvolleyball.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 350)
      }
    }
  }
`

export default IndexPage
